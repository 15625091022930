var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{key:_vm.key,staticClass:"lib-view"},[(!_vm.isChecked)?_c('div',{staticClass:"profile-nav-bg",staticStyle:{"background":"url(images/banner.jpg) center/cover","transition":"all 0.3s"}},[_c('a-row',{attrs:{"type":"flex","md":8,"xs":4}},[_c('search-box',{on:{"mouse":_vm.searchBoxMouseStatus,"search":_vm.search}})],1)],1):_vm._e(),_c('store',{ref:"store",style:(_vm.isChecked ? 'margin-top:-70px;' : 'padding-top:20px;'),attrs:{"isChecked":_vm.isChecked,"isShowEdit":_vm.isShowEdit,"isShowDelete":_vm.isShowDelete,"eventSettingEnabled":_vm.eventSettingEnabled,"settingsEnabled":_vm.settingsEnabled,"i18nVulnerabilityColumns":_vm.i18nVulnerabilityColumns,"metadataTypes":_vm.i18nMetadataTypes,"quillOptions":_vm.quillOptions,"propScanReport":_vm.scanReport,"successMsg":_vm.successMsg,"formateDate":_vm.formateDate},on:{"reload":_vm.reloadTree,"openDetial":_vm.openDetial,"handleLibMenuClick":_vm.handleLibMenuClick}}),_c('SettingsDrawer',{attrs:{"folibRepository":this.folibRepository,"settingVisible":_vm.settingVisible},on:{"settingDrawerClose":_vm.settingDrawerClose}}),_c('EventPageDrawer',{attrs:{"folibRepository":this.folibRepository,"eventPageVisible":_vm.eventPageVisible},on:{"eventDrawerClose":function($event){_vm.eventPageVisible=false}}}),_c('a-drawer',{attrs:{"placement":"right","width":"45%","title":_vm.currentTreeNode.name,"visible":_vm.viewCodeVisible},on:{"close":_vm.closeViewCodeDialog}},[_c('div',{staticClass:"mx-auto m-50"},[_c('div',{staticClass:"mb-50"},[_c('a-card',{staticClass:"header-solid",attrs:{"bordered":false}},[(_vm.currentFileDetial && _vm.currentFileDetial.listTree)?_c('a-directory-tree',{attrs:{"replaceFields":{ title: 'name', children: 'children' },"tree-data":_vm.currentFileDetial.listTree}}):_vm._e()],1),(
              _vm.currentFileDetial &&
              _vm.viewCodes &&
              _vm.folibRepository.layout !== 'Docker'
            )?_c('prism-editor',{staticClass:"my-editor height-300",attrs:{"highlight":_vm.highlighterHandle,"line-numbers":false,"readonly":true},model:{value:(_vm.viewCodes),callback:function ($$v) {_vm.viewCodes=$$v},expression:"viewCodes"}}):_vm._e(),(
              _vm.currentFileDetial &&
              _vm.currentManifest &&
              _vm.folibRepository.layout === 'Docker'
            )?_c('a-tabs',{staticClass:"tabs-sliding",attrs:{"default-active-key":"1"}},[_c('a-tab-pane',{key:"1",attrs:{"tab":"Layers"}},[_c('a-timeline',[(_vm.currentManifest.config[_vm.index])?_vm._l((_vm.currentManifest.config),function(key,index){return _c('a-timeline-item',{key:index,attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(index)+" "),_c('p',[_vm._v(" "+_vm._s(_vm.currentManifest.config[index])+" ")])])}):_vm._e()],2)],1),_c('a-tab-pane',{key:"2",attrs:{"tab":_vm.$t('Storage.MakingHistory')}},[_c('a-timeline',[(_vm.currentManifest.history[_vm.index])?_vm._l((_vm.currentManifest.history),function(key,index){return _c('a-timeline-item',{key:index,attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.formateDate(key.created))+" "),_c('p',[_vm._v(" "+_vm._s(key.created_by)+" ")])])}):_vm._e()],2)],1)],1):_vm._e()],1)])]),_c('VunlerabilityReport',{attrs:{"report":_vm.scanReport.report,"reportVisible":_vm.detialVisible},on:{"closeReport":_vm.closeReport}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }